<template>
	<transition name="fade">
		<div v-if="show">

		
		<div class="popup-overlay" data-bg-color="rgba(0,0,0,0.8)" style="background-color: rgba(0, 0, 0, 0.8);">

            </div>
            
            <div id="contact" class="popup-section contact-section typo-light active-section" style="display: flex;">
                
                <!-- CLOSE BUTTON -->
                <div class="close-section">
                    <a @click="$emit('update:show', false)" href="#" class="button button-icon color-button-white fs-30 border-op-light-2 radius-full"><i class="pe-7s-close"></i></a>
                </div>

                <div class="inner-section animated fadeIn" data-anim-in="fadeIn" data-anim-out="fadeOut" style="animation-delay: 0s;">
                    <div class="container">
                        
                        <div class="row gt60 align-items-center d-flex justify-content-center">

                            <div class="col-lg-6">

                                <div class="section-heading-wrapper" data-ckav-smd="align-center margin-t-30">
                                    <h2 class="heading-section text-upper default bold-900 animated fadeInRight" data-anim-in="fadeInRight|0.1" data-ckav-smd="medium margin-b-0" style="animation-delay: 0.1s;">Let's Build</h2>
                                </div>
                                <hr class="color-border-default margin-tb-30 width-px-50 margin-l-0 border-t-2 animated fadeInRight" data-anim-in="fadeInRight|0.2" data-ckav-smd="margin-auto" style="animation-delay: 0.2s;">
                                <div>
                                    <div class="field-wrp">
										<p v-if="formError" class="text-danger">{{ formError }}</p>
                                        <div class="row gt10">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input v-model="contactForm.name" class="form-control radius-10" type="text" placeholder="Name*">
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input v-model="contactForm.email" class="form-control radius-10" type="email" placeholder="Email*">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group">
                                            <input v-model="contactForm.phone" class="form-control radius-10" type="text" placeholder="Phone"> 
                                        </div>
            
                                        <div class="form-group">
                                            <select v-model="contactForm.interest" class="form-control radius-6">
                                                <option value="none">Select Your Interest</option>
                                                <option value="music">Music</option>
                                                <option value="metaverse">Metaverse</option>
                                                <option value="gameDev">Game Dev</option>
                                                <option value="3dAssets">3D Assets</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <textarea v-model="contactForm.message" class="form-control radius-10" placeholder="Message" cols="30" rows="6"></textarea>
                                        </div>
                                    </div>
                                    <button @click="handleFormSubmit" class="button radius-10 solid color-button-default margin-0">SUBMIT</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
		</div>
	</transition>
</template>

<script>
import { ref } from 'vue'

export default {
	props: ['show'],
	emits: ['update:show'],
	setup(props, context) {
		const contactForm = ref({interest: 'none'})

		const formError = ref(null)
		const handleFormSubmit = async () => {
			formError.value = null
			if(!contactForm.value.name || !contactForm.value.email) {
				formError.value = 'Please fill out all fields'
				return
			}

			try {
				const res = await fetch('https://trigger.kimiko.io?source=quinnanthony.com&event=contact_form&account_id=chris@kimiko.io&api_key=JlHIKWhn3i7f9VVU1ojz', {
					method: 'POST',
					body: JSON.stringify(contactForm.value)
				})

				const rjson = await res.json()
				if(!rjson.success) {
					formError.value = 'Error submitting form. Please try again later'
					return
				}
				context.emit('update:show', false)
			} catch(err) {
				console.log(err)
				formError.value = 'Error submitting form. Please try again later'
			}
			
		}

		return { contactForm, formError, handleFormSubmit }
	}
}
</script>

<style>
	.fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
<template>
  <iframe frameborder="0" src="https://itch.io/embed/1412850?linkback=true&amp;border_width=0&amp;bg_color=000000&amp;fg_color=ffffff&amp;border_color=000000" width="550" height="165"><a href="https://quinnful-games.itch.io/black-heart-cursed-version">Black Heart - (Cursed Version) by Quinnful Games</a></iframe>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
	<video autoplay muted loop playsinline id="myVideo">
		<source src="quinn.mp4" type="video/mp4">
	</video>

    <div class="ckav-body tooltip-light" :class="[ showing ? 'active-popup-section' : '']" style="background-color:black">

        <header class="header-area intro-element">
            <div class="container-fluid">

                <div class="logo-wrp flex-cc">
                    <a href="#" class="logo-link width-px-120" data-ckav-smd="width-px-60">
                        <img src="images/qalogo.png" alt="logo">
                    </a>
                </div>

            </div>
        </header>

        <div class="navigation-area intro-element d-none">
            <ul class="navigation-ul">
                <li class="navigation-li">
                    <a href="#about" class="navigation-a section-post">
                        <span class="icon"><i class="icon-book-open"></i></span>
                        <span class="text">About <i class="fas fa-sort-down"></i></span>
                    </a>
                </li>
                <li class="navigation-li">
                    <a href="#team" class="navigation-a section-post">
                        <span class="icon"><i class="icon-user"></i></span>
                        <span class="text">Team <i class="fas fa-sort-down"></i></span>
                    </a>
                </li>
                <li class="navigation-li">
                    <a href="#services" class="navigation-a section-post">
                        <span class="icon"><i class="icon-settings"></i></span>
                        <span class="text">Services <i class="fas fa-sort-down"></i></span>
                    </a>
                </li>
                <li class="navigation-li">
                    <a href="#portfolio" class="navigation-a section-post">
                        <span class="icon"><i class="icon-camera"></i></span>
                        <span class="text">Portfolio <i class="fas fa-sort-down"></i></span>
                    </a>
                </li>
                <li class="navigation-li">
                    <a href="#contact" class="navigation-a section-post">
                        <span class="icon"><i class="icon-envelope"></i></span>
                        <span class="text">Contact <i class="fas fa-sort-down"></i></span>
                    </a>
                </li>
            </ul>
        </div>


        <div class="home-area intro-section flex-bc padding-b-large" data-ckav-smd="padding-b-0 flex-cc">
            <div class="container zindex-1 intro-element">

                <!-- INTRO TEXT -->
                <div v-if="showingGame" class="intro-text typo-light align-center">
                    
                    <div class="row d-flex justify-content-center center">
                        <Game />
                    </div>
                    <a href="#" @click="showing=true" class="set-popup button button-xlarge color-button-default color-hov-button-white solid radius-10" data-ckav-smd="button-medium">Connect</a>
                    <a href="#" @click="showingGame=false" class="set-popup button button-xlarge color-button-default color-hov-button-white solid radius-10" data-ckav-smd="button-medium">Home</a>
                    
                </div>
                <div v-else class="intro-text typo-light align-center">
                    <h2 class="heading xlarge bold-900 text-upper" data-ckav-smd="large">Quinn Anthony</h2>
                    <p class="heading-sub width-60 margin-auto" data-ckav-smd="width-100 mini">
                        Creator of things, builder of worlds. Quinn Anthony is a pioneer and the future of global entertainment.
                    </p>
                    <a href="#" @click="showing=true" class="set-popup button button-xlarge color-button-default color-hov-button-white solid radius-10" data-ckav-smd="button-medium">Connect</a>
                    <a href="https://quinnful-games.itch.io" target="_blank" class="set-popup button button-xlarge color-button-default color-hov-button-white solid radius-10" data-ckav-smd="button-medium">Play</a>
                    
                </div>

                

            </div>

        </div>

        <!-- <div class="social-area intro-element">
            <a href="#social" class="section-post button button-icon color-button-white border-op-light-2 radius-full"><i class="icon-share"></i></a>
        </div> -->

        <div class="popup-area">
	        <ContactForm v-model:show="showing" />

            
        </div>

        <div class="footer-area intro-element">
            <div class="container">
                <p class="c-text">                
                    © Quinn Anthony 3008
                </p>
            </div>
        </div>
	</div>
</template>

<script>
import { ref } from 'vue'

import ContactForm from "@/components/ContactForm"
import Game from '@/components/Game'
export default {
  name: 'Home',
  components: { ContactForm, Game },
  setup() {
	  const showing = ref(null)
        const showingGame = ref(false)
	  return {showing, showingGame}
  }
}
</script>


<style scoped>
#myVideo {
  position: fixed;
  object-fit: cover;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.button {
    margin-left: 10px;
    margin-right: 10px;
}
</style>